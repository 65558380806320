@charset "UTF-8";
/*
ユーティリティ系おまとめファイル
*/
/*
██████   █████  ███████ ███████
██   ██ ██   ██ ██      ██
██████  ███████ ███████ █████
██   ██ ██   ██      ██ ██
██████  ██   ██ ███████ ███████
*/
/*
██████  ██████  ███████  █████  ██   ██ ██████   ██████  ██ ███    ██ ████████
██   ██ ██   ██ ██      ██   ██ ██  ██  ██   ██ ██    ██ ██ ████   ██    ██
██████  ██████  █████   ███████ █████   ██████  ██    ██ ██ ██ ██  ██    ██
██   ██ ██   ██ ██      ██   ██ ██  ██  ██      ██    ██ ██ ██  ██ ██    ██
██████  ██   ██ ███████ ██   ██ ██   ██ ██       ██████  ██ ██   ████    ██
*/
/*
 ██████  ██████  ██       ██████  ██████
██      ██    ██ ██      ██    ██ ██   ██
██      ██    ██ ██      ██    ██ ██████
██      ██    ██ ██      ██    ██ ██   ██
 ██████  ██████  ███████  ██████  ██   ██
*/
/*
████████ ███████ ██   ██ ████████
   ██    ██       ██ ██     ██
   ██    █████     ███      ██
   ██    ██       ██ ██     ██
   ██    ███████ ██   ██    ██
*/
.u-taL {
  text-align: left !important; }

.u-taC {
  text-align: center !important; }
  @media screen and (max-width: 568px) {
    .u-taC--xs-taL {
      text-align: left !important; } }
  @media screen and (max-width: 768px) {
    .u-taC--sm-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1024px) {
    .u-taC--md-taL {
      text-align: left !important; } }
  @media screen and (max-width: 1280px) {
    .u-taC--lg-taL {
      text-align: left !important; } }

.u-taR {
  text-align: right !important; }

.u-only-xs {
  display: none !important; }
  @media screen and (max-width: 568px) {
    .u-only-xs {
      display: block !important; } }

.u-only-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-only-sm {
      display: block !important; } }
  @media screen and (max-width: 568px) {
    .u-only-sm {
      display: none !important; } }

.u-only-md {
  display: none !important; }
  @media screen and (max-width: 1024px) {
    .u-only-md {
      display: block !important; } }
  @media screen and (max-width: 768px) {
    .u-only-md {
      display: none !important; } }

@media screen and (max-width: 1024px) {
  .u-only-lg {
    display: none !important; } }

.u-under-sm {
  display: none !important; }
  @media screen and (max-width: 768px) {
    .u-under-sm {
      display: block !important; } }

.u-not-under-sm {
  display: block !important; }
  @media screen and (max-width: 768px) {
    .u-not-under-sm {
      display: none !important; } }

.u-overflow-hidden {
  overflow: hidden !important; }

.c-topicspath {
  max-width: 1170px;
  width: 100%;
  margin: 0 auto;
  padding: 10px 0; }
  @media screen and (max-width: 1200px) {
    .c-topicspath {
      padding: 10px 30px; } }
  @media screen and (max-width: 768px) {
    .c-topicspath {
      text-align: left;
      padding: 10px 20px; } }
  @media screen and (max-width: 568px) {
    .c-topicspath {
      padding: 10px 15px; } }
  .c-topicspath li {
    display: inline-block;
    font-size: 12px; }
    @media screen and (max-width: 768px) {
      .c-topicspath li {
        font-size: 0.7em; } }
    .c-topicspath li::after {
      content: "\f105";
      font-family: "Font Awesome 5 Free";
      font-weight: 600;
      display: inline-block;
      margin-left: 8px; }
    .c-topicspath li:last-child::after {
      content: none; }
  .c-topicspath__wrapper {
    background-color: #F4F8FA; }

.l-lowerCaption {
  position: relative;
  width: 100%;
  height: 155px;
  background-color: #FFFFFF;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1170px;
  margin: 0 auto; }
  .l-lowerCaption__title {
    transform: translateY(-50%);
    color: #2C3555;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 10px 0;
    width: 100%;
    font-family: "Shippori Mincho B1", "游明朝", "Yu Mincho", YuMincho, "ヒラギノ明朝 ProN W6", "HiraMinProN-W6", "HG明朝E", "ＭＳ Ｐ明朝", "MS PMincho", "MS 明朝", serif;
    font-size: 40px;
    color: #2C3555;
    font-weight: 400; }
    @media screen and (max-width: 1200px) {
      .l-lowerCaption__title {
        padding: 10px 30px; } }
    @media screen and (max-width: 768px) {
      .l-lowerCaption__title {
        width: 100%;
        padding: 0 20px; } }
    .l-lowerCaption__title::before {
      content: '';
      background-image: url("/inc/image/common/img_lowerTitle.png");
      background-repeat: no-repeat;
      width: 80px;
      height: 80px;
      position: absolute;
      left: -40px;
      top: 50%;
      z-index: -1;
      transform: translateY(-50%); }
      @media screen and (max-width: 1200px) {
        .l-lowerCaption__title::before {
          left: -10px; } }

#privacy .p-analysisTool {
  margin-top: 20px;
  border: 1px solid #000;
  padding: 20px; }
  #privacy .p-analysisTool__heading {
    font-size: 22px;
    margin-bottom: 15px; }
    @media screen and (max-width: 568px) {
      #privacy .p-analysisTool__heading {
        font-size: 18px;
        margin-bottom: 12px; } }
  #privacy .p-analysisTool p:last-child {
    margin-bottom: 0; }
